<template>
  <div class='border-t bg-white flex flex-col py-12'>
    <div class='layout-container w-full bg-white flex flex-col-reverse lg:flex-row-reverse lg:gap-x-8 gap-y-8 justify-between px-4 lg:px-0 text-xs lg:text-sm text-gray-700'>
      <div class='flex flex-col justify-between items-start lg:items-end'>
        <div>
          <img src='https://d3r2ol85dktaw0.cloudfront.net/conferences/absolute/astrazeneca-logo-3.png'
             class='h-12 cursor-pointer mb-6 w-auto'
             @click='goToHome'/>
        </div>
        <div
          v-if='hasValidToken' 
          class='text-left uppercase border px-4 py-2 text-center rounded-md hover:shadow-md cursor-pointer'
          @click='logoutApp'>
          logout
        </div>
      </div>
      <div class='text-sm'>
        <div class='mt-2 flex flex-col lg:flex-row justify-start items-center lg:items-start'>
          <span class='uppercase inline-block opacity-70 w-full lg:w-48'>Tel</span>
          <span class='block text-left w-full lg:w-auto'>02-2188-0800</span>
        </div>
        <div class='mt-2 flex flex-col lg:flex-row justify-start items-center lg:items-start'>
          <span class='uppercase inline-block opacity-70 w-full lg:w-48'>주소</span> 
           <span class='block text-left w-full lg:w-auto'>서울시 강남구 영동대로 517아셈타워21층 한국아스트라제네카 (우편번호:06164)</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'

export default {
  name: 'AbsoluteLayoutFooter',
  computed: {
    ...mapState('users', [
      'hasValidToken',
    ]),
    ...mapGetters('events', [
      'eventConfigLogoImageUrl',
    ]),
  },
  methods: {
    ...mapActions('users', [
      'logout',
      'checkTokenStatus',
    ]),
    logoutApp () {
      this.logout().then(() => {
        this.$router.push({name: 'Login'})
      })
    },
    goToHome () {
      this.$router.push({name: 'Home'}).catch(() => {})
    },
  },
  created () {
    this.checkTokenStatus()
  }
}
</script>
